import React, { useEffect, useState } from "react";

export default function Turne() {
  const [pageContent, setPageContent] = useState();

  useEffect(() => {
    // get data from GitHub api
    fetch(`https://olleburl.in/timbuk/wp-json/wp/v2/pages/24`)
      .then((response) => response.json()) // parse JSON from request
      .then((resultData) => {
        setPageContent(resultData.content.rendered);
      }); // set data for the number of stars
  }, []);

  return (
    <div className="py-8">
      <div dangerouslySetInnerHTML={{ __html: pageContent }} />
    </div>
  );
}
