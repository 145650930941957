import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import TurneClient from "../components/TurneClient";

export default function TurnePage() {
  return (
    <Layout>
      <SEO keywords={[`Timbuktu`, `turnédatum`]} title="Turné" />

      <TurneClient />
    </Layout>
  );
}
